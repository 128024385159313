import React from "react";
import { FaInstagram, FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";
import './App.css'; // Importando o CSS separado

const App = () => {
  return (
    <div className="container">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <img className="profile-image" src={`${process.env.PUBLIC_URL}/perfil.png`} height={150} width={150} alt="Profile" />
        <h1 className="name">Adão Braga</h1>
        <p className="bio">Software Engineer and Automation | AI-Powered Customer Experience</p>

        <div className="links-container">
          <a className="link-button" href="https://intelihube.com/oadaobraga">Quem Sou?</a>
          <a className="link-button" href="https://intelihube.com/shop">Shop</a>
          <a className="link-button" href="https://blog.intelihube.com">Blog</a>
        </div>

        <div className="social-icons">
          <a className="icon-wrapper" href="https://www.instagram.com/oadaobraga" whileHover={{ scale: 1.2 }}>
            <FaInstagram color="white" size="24" />
          </a>
          <a className="icon-wrapper" href="https://www.linkedin.com/in/braganet" whileHover={{ scale: 1.2 }}>
            <FaLinkedin color="white" size="24" />
          </a>
          <a className="icon-wrapper" href="mailto:me@intelihube.com" whileHover={{ scale: 1.2 }}>
            <FaEnvelope color="white" size="24" />
          </a>
        </div>
      </motion.div>

      {/* Rodapé */}
      <footer className="footer">
        <p>By intelihube</p>
      </footer>
    </div>
  );
};

export default App;
